body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin-slow {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.animate-spin-slow {
  animation: spin-slow 10s linear infinite;
}
.glow-effect {
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.5);
}
.glow-effect:hover {
  box-shadow: 0 0 25px rgba(255, 165, 0, 0.8);
}

@keyframes pulse-sparkle {
  0% { color: #FFFFFF; filter: drop-shadow(0 0 8px rgba(224, 76, 22, 0.7)); }
  33% { color: #FFFFFF; filter: drop-shadow(0 0 8px rgba(139, 92, 246, 0.7)); }
  66% { color: #FFFFFF; filter: drop-shadow(0 0 8px rgba(59, 130, 246, 0.7)); }
  100% { color: #FFFFFF; filter: drop-shadow(0 0 8px rgba(224, 76, 22, 0.7)); }
}
.animate-pulse-sparkle {
  animation: pulse-sparkle 3s infinite ease-in-out;
}

/* New background animation */
@keyframes pulse-sparkle-bg {
  0% { background: linear-gradient(135deg, #E04C16 0%, #8B5CF6 50%, #3B82F6 100%); }
  33% { background: linear-gradient(135deg, #8B5CF6 0%, #3B82F6 50%, #E04C16 100%); }
  66% { background: linear-gradient(135deg, #3B82F6 0%, #E04C16 50%, #8B5CF6 100%); }
  100% { background: linear-gradient(135deg, #E04C16 0%, #8B5CF6 50%, #3B82F6 100%); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}